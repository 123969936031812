<template>
  <v-card :loading="loading">
    <v-card-title>
      Billing Info
      <v-spacer></v-spacer>
      <img src="../../assets/images/powered-by-stripe.svg" alt="" />
    </v-card-title>
    <v-simple-table>
      <template v-slot:default>
        <tbody v-if="billingInfo">
          <tr>
            <td style="width: 300px">Customer Stripe ID</td>
            <td>
              <span ref="stripeCustomerId">
                {{ billingInfo.stripeCustomerId }}
              </span>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    class="ma-2"
                    text
                    icon
                    v-if="billingInfo.stripeCustomerId"
                    @click.prevent="copyStripeCustomerId"
                  >
                    <img
                      svg-inline
                      src="../../assets/images/ico-copy.svg"
                      alt=""
                    />
                  </v-btn>
                </template>
                <span>Copy</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    class="ma-2"
                    text
                    icon
                    color="primary"
                    :href="`https://dashboard.stripe.com/customers/${billingInfo.stripeCustomerId}`"
                    target="_blank"
                  >
                    <v-icon>link</v-icon>
                  </v-btn>
                </template>
                <span>Stripe</span>
              </v-tooltip>
            </td>
          </tr>
          <template v-if="billingInfo.stripePaymentMethod">
            <template
              v-if="billingInfo.stripePaymentMethod.type === 'sepa_debit'"
            >
              <tr>
                <td>IBAN</td>
                <td>
                  XXXXXXXXXXXXXXXXXX{{
                    billingInfo.stripePaymentMethod.sepa_debit.last4
                  }}
                </td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <td>Card Type</td>
                <td>{{ billingInfo.stripePaymentMethod.card.brand }}</td>
              </tr>
              <tr>
                <td>Card Number</td>
                <td>
                  XXXX XXXX XXXX
                  {{ billingInfo.stripePaymentMethod.card.last4 }}
                </td>
              </tr>
              <tr>
                <td>Card Expires</td>
                <td>
                  {{ billingInfo.stripePaymentMethod.card.exp_month }}/{{
                    billingInfo.stripePaymentMethod.card.exp_year
                  }}
                </td>
              </tr>
            </template>
          </template>
          <tr>
            <td>Currency</td>
            <td>{{ billingInfo.stripeCustomer.currency }}</td>
          </tr>
          <tr>
            <td>Billing Email</td>
            <td>
              <span ref="billingEmail">
                {{ billingInfo.stripeCustomer.metadata.billing_email }}
              </span>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    class="ma-2"
                    text
                    icon
                    v-if="billingInfo.stripeCustomer.metadata.billing_email"
                    @click.prevent="copyBillingEmail"
                  >
                    <img
                      svg-inline
                      src="../../assets/images/ico-copy.svg"
                      alt=""
                    />
                  </v-btn>
                </template>
                <span>Copy</span>
              </v-tooltip>
            </td>
          </tr>
        </tbody>
        <div v-else class="text-center py-8">No payment method found</div>
      </template>
    </v-simple-table>
  </v-card>
</template>
<script>
import { API } from "../../api";
import copyText from "../../utils/copyText";

export default {
  name: "CompanyPaymentMethod",
  props: {
    id: {
      default: null,
      type: String,
    },
  },
  data() {
    return {
      billingInfo: null,
      loading: false,
    };
  },
  watch: {
    id: {
      immediate: true,
      async handler(val) {
        if (val) {
          this.loading = true;
          this.billingInfo = null;
          try {
            const billingInfo = await API.getBillingInfo({
              companyId: this.id,
            });
            this.billingInfo = billingInfo;
            this.loading = false;
          } catch (error) {
            this.loading = false;
          }
        }
      },
    },
  },
  methods: {
    copyStripeCustomerId(e) {
      copyText({
        target: this.$refs.stripeCustomerId,
        trigger: e.currentTarget,
      });
    },
    copyBillingEmail(e) {
      copyText({
        target: this.$refs.billingEmail,
        trigger: e.currentTarget,
      });
    },
  },
};
</script>
