import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VList,{attrs:{"bg-color":"transparent"}},[_c(VImg,{staticClass:"mx-auto mt-5 mb-10",attrs:{"max-width":"80","src":require("../assets/images/logo.svg")}}),_vm._l((_vm.list),function(item){return _c(VListItem,{key:item.text,attrs:{"to":item.link,"variant":"contained"}},[_c(VListItemAvatar,{attrs:{"start":""}},[_c(VIcon,{attrs:{"icon":item.icon}})],1),_c(VListItemTitle,{domProps:{"textContent":_vm._s(item.text)}})],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }