<template>
  <div>
    <company-profile :id="id" class="mb-10"></company-profile>
    <forest-table class="mb-10" :companyId="id"></forest-table>
    <company-billing-info class="mb-10" :id="id"></company-billing-info>
    <company-invoices class="mb-10" :id="id"></company-invoices>
  </div>
</template>

<script>
import CompanyProfile from "../components/Company/CompanyProfile.vue";
import ForestTable from "../components/Forest/ForestTable.vue";
import CompanyBillingInfo from "../components/Company/CompanyBillingInfo.vue";
import CompanyInvoices from "../components/Company/CompanyInvoices.vue";

export default {
  name: "CompanyView",
  components: {
    CompanyProfile,
    ForestTable,
    CompanyBillingInfo,
    CompanyInvoices,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
};
</script>
