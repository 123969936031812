import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"loading":_vm.loading}},[_c(VCardTitle,[_vm._v(" Billing Info "),_c(VSpacer),_c('img',{attrs:{"src":require("../../assets/images/powered-by-stripe.svg"),"alt":""}})],1),_c(VSimpleTable,{scopedSlots:_vm._u([{key:"default",fn:function(){return [(_vm.billingInfo)?_c('tbody',[_c('tr',[_c('td',{staticStyle:{"width":"300px"}},[_vm._v("Customer Stripe ID")]),_c('td',[_c('span',{ref:"stripeCustomerId"},[_vm._v(" "+_vm._s(_vm.billingInfo.stripeCustomerId)+" ")]),_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.billingInfo.stripeCustomerId)?_c(VBtn,_vm._g({staticClass:"ma-2",attrs:{"text":"","icon":""},on:{"click":function($event){$event.preventDefault();return _vm.copyStripeCustomerId.apply(null, arguments)}}},on),[_c('img',{attrs:{"svg-inline":"","src":require("../../assets/images/ico-copy.svg"),"alt":""}})]):_vm._e()]}}],null,false,1789625939)},[_c('span',[_vm._v("Copy")])]),_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"ma-2",attrs:{"text":"","icon":"","color":"primary","href":("https://dashboard.stripe.com/customers/" + (_vm.billingInfo.stripeCustomerId)),"target":"_blank"}},on),[_c(VIcon,[_vm._v("link")])],1)]}}],null,false,2237496107)},[_c('span',[_vm._v("Stripe")])])],1)]),(_vm.billingInfo.stripePaymentMethod)?[(_vm.billingInfo.stripePaymentMethod.type === 'sepa_debit')?[_c('tr',[_c('td',[_vm._v("IBAN")]),_c('td',[_vm._v(" XXXXXXXXXXXXXXXXXX"+_vm._s(_vm.billingInfo.stripePaymentMethod.sepa_debit.last4)+" ")])])]:[_c('tr',[_c('td',[_vm._v("Card Type")]),_c('td',[_vm._v(_vm._s(_vm.billingInfo.stripePaymentMethod.card.brand))])]),_c('tr',[_c('td',[_vm._v("Card Number")]),_c('td',[_vm._v(" XXXX XXXX XXXX "+_vm._s(_vm.billingInfo.stripePaymentMethod.card.last4)+" ")])]),_c('tr',[_c('td',[_vm._v("Card Expires")]),_c('td',[_vm._v(" "+_vm._s(_vm.billingInfo.stripePaymentMethod.card.exp_month)+"/"+_vm._s(_vm.billingInfo.stripePaymentMethod.card.exp_year)+" ")])])]]:_vm._e(),_c('tr',[_c('td',[_vm._v("Currency")]),_c('td',[_vm._v(_vm._s(_vm.billingInfo.stripeCustomer.currency))])]),_c('tr',[_c('td',[_vm._v("Billing Email")]),_c('td',[_c('span',{ref:"billingEmail"},[_vm._v(" "+_vm._s(_vm.billingInfo.stripeCustomer.metadata.billing_email)+" ")]),_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.billingInfo.stripeCustomer.metadata.billing_email)?_c(VBtn,_vm._g({staticClass:"ma-2",attrs:{"text":"","icon":""},on:{"click":function($event){$event.preventDefault();return _vm.copyBillingEmail.apply(null, arguments)}}},on),[_c('img',{attrs:{"svg-inline":"","src":require("../../assets/images/ico-copy.svg"),"alt":""}})]):_vm._e()]}}],null,false,1143550412)},[_c('span',[_vm._v("Copy")])])],1)])],2):_c('div',{staticClass:"text-center py-8"},[_vm._v("No payment method found")])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }